import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "./Header";
import {
  MapContainer,
  TileLayer,
  Marker,
  GeoJSON,
  Polygon,
  ImageOverlay,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import "mapbox-gl/dist/mapbox-gl.css";
import Map from "react-map-gl";
import { map } from "leaflet";
import HorizontalTimeline from "react-horizontal-timeline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Chrono } from "react-chrono";

const HurricaneIdalia = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const rasterLayerUrl = "/CedarKey-removebg-preview.png";
  const bounds = [[ 29.126974772291366, -83.05418875091242], [ 29.155092040684305, -83.02670030321836]];
  const materialLayerUrl = '/idaliaMaterial.png'

  const nav = useNavigate();
  const n = useNavigate();

  const handleChange = (event) => {
    nav("/deeper analysis for Hurricane Idalia");
  };


  const handleItemClick = (hurricane) => {
    // You can navigate to the selected page here
    n(`/${hurricane}`);
  }; 

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Hurricane Idalia"
          subtitle=" Florida, USA, 30.08.2023, Category IV"
        />
        <Box style = {{ display: 'flex', gap: "20px"}}>
        <Box>
        <select style = {{width: "120px", height: "50px", border: "1px solid #999",fontSize: "14px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc"}} onChange={(e) => handleItemClick(e.target.value)}>
        <option value="hurricane idalia"> Hurricane Idalia </option>
        <option value="hurricane ">Hurricane Ian </option>
      </select>
      </Box>
      <Box> 
      <Button
      style = {{width: "120px", height: "50px", border: "1px solid #999",fontSize: "12px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc"}}
            onClick={handleChange}
          >
            <h4>
              {" "}
              Individual - Roof Analysis{" "}
            </h4>
          </Button>

      </Box>
        </Box>
      </Box>
      <Box style={{ display: "grid", gap: "20px" }}>
        <Box style={{ display: "flex", gap: "20px" }}>
          <MapContainer
            style={{ height: "30vh ", width: " 50% " }}
            zoom={13.5}
            center={[ 29.13964715689734, -83.03247838865639]}
          >
          <div className="custom-box">
            
        <Box style = {{display: 'flex', gap: '20px', height: "20px"}}> 
        <h4
          style={{
            color: "#868dfb",
            marginTop: "-4px"
          }}
        >
          Map1 : Damage Extent
        </h4>
        </Box>

      </div>

            <TileLayer
              url={
                "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=yDbiZJmJ3zEASmbq6sx7"
              }
            ></TileLayer>
            <ImageOverlay
            url={rasterLayerUrl}
            bounds={bounds}
            zIndex={-999}
            opacity={0.7}
          ></ImageOverlay>
          </MapContainer>

          <Box style = {{ display: "flex",  
            flexDirection: "column",  
            flexWrap: "wrap", 
            height: "210px",  
            width: "50%", border: "1px white solid"}}> 

            <h3 style = {{display: "block", position: "relative", marginLeft: "30%"}}> Total No.of Buildings Damaged: 658</h3>
          
            <Box style = {{display: "flex", gap: "50px"}}> 
                      <Box>
                              <Box color="white" bgcolor="maroon" height="20px" width="400px">
                              <h3 style = {{color: 'black', marginLeft: "60%"}}> 372 </h3>
                              </Box>
                              <Box color="white" bgcolor="red" height="20px" width="220px">
                              <h3 style = {{color: 'black', marginLeft: "20%"}}> 110 </h3>
                              </Box>
                              <Box color="white" bgcolor="orange" height="20px" width="280px">
                              <h3 style = {{color: 'black', marginLeft: "40%"}}> 128 </h3>
                              </Box>
                              <Box color="white" bgcolor="yellow" height="20px" width="100px">
                              <h3 style = {{color: 'black', marginLeft: "20%"}}> 48 </h3>
                              </Box>
                      </Box>
                      <Box> 
                                    <Box style = {{display: "flex", gap: "5px"}}> 
                                    <Box color="white" bgcolor="maroon" height="20px" width="20px"></Box>
                                    <Box style = {{marginTop: "-20px"}}> <h6> High Damage</h6></Box>
                                    </Box>
                                    <Box style = {{display: "flex", gap: "5px"}}> 
                                    <Box color="white" bgcolor="red" height="20px" width="20px"></Box>
                                    <Box style = {{marginTop: "-20px"}}> <h6> Medium Damage</h6></Box>
                                    </Box>
                                    <Box style = {{display: "flex", gap: "5px"}}> 
                                    <Box color="white" bgcolor="orange" height="20px" width="20px"></Box>
                                    <Box style = {{marginTop: "-20px"}}> <h6> Low Damage</h6></Box>
                                    </Box>
                                    <Box style = {{display: "flex", gap: "5px"}}> 
                                    <Box color="white" bgcolor="yellow" height="20px" width="20px"></Box>
                                    <Box style = {{marginTop: "-20px"}}> <h6> Very Low Damage</h6></Box>
                                    </Box>
                      
                      
                      </Box>

            
            </Box>

          </Box>
        </Box>

      <Box style = {{display: "flex" , gap: "20px"}}>
        <MapContainer
          style={{ height: "30vh ", width: " 50% " }}
          zoom={13.5}
          center={[29.13964715689734, -83.03247838865639]}
        >
        <div className="custom-box">
            
        <Box style = {{display: 'flex', gap: '20px', height: "20px"}}> 
        <h4
          style={{
            color: "#868dfb",
            marginTop: "-4px"
          }}
        >
          Map2 : Material Distribution
        </h4>
        </Box>

      </div>

          <TileLayer
            url={
              "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=yDbiZJmJ3zEASmbq6sx7"
            }
          ></TileLayer>
            <ImageOverlay
            url= {materialLayerUrl}
            bounds={bounds}
            zIndex={-999}
            opacity={0.9}
          ></ImageOverlay>
        </MapContainer>
        <Box style = {{display: "flex", gap: "20px"}}> 
                                  <Box  style = {{height: "210px",width: "280px", border: "1px white solid"}}> 
                                  <h3 style = {{marginLeft: "30%"}}> Material distribution </h3>
                                  <Box color="white" bgcolor="yellow" height="30px" width="250px">
                                  <h3 style = {{color: 'black', marginLeft: "60%"}}> Asphalt </h3>
                                  </Box>
                                  <Box color="white" bgcolor="green" height="30px" width="170px">
                                  <h3 style = {{color: 'black', marginLeft: "40%"}}> Concrete </h3>
                                  </Box>
                                  <Box color="white" bgcolor="pink" height="30px" width="120px">
                                  <h3 style = {{color: 'black', marginLeft: "60%"}}> Metal </h3>
                                  </Box>
                                  </Box>
                                  <Box>
                                          <Box style = {{height: "70px",width: "300px", border: "1px white solid"}}>
                                            <Box style = {{display: "flex", gap: "10px"}}>
                                            <h4 style = {{marginLeft: "5px", marginTop: "8%"}}> Total Area Analysed: </h4>
                                            <h2 style = {{marginLeft: "10px"}}> 4.69 sq.km </h2>
                                            </Box>
                                          </Box>
                                          <Box style = {{height: "70px",width: "300px", border: "1px white solid"}}>
                                            <Box style = {{display: "flex", gap: "10px"}}>
                                            <h4 style = {{marginLeft: "5px", marginTop: "8%"}}> Avg. Roof Age: </h4>
                                            <h2 style = {{marginLeft: "10px"}}> 10-15 years </h2>
                                            </Box>
                                          </Box>
                                          <Box style = {{height: "70px",width: "300px", border: "1px white solid"}}>
                                            <Box style = {{display: "flex", gap: "10px"}}>
                                                <Box color="white" bgcolor="yellow" height="20px" width="50px" marginTop= "20px"  marginLeft = "20px"> <h4> 338</h4></Box>
                                            
                                            
                                                <Box color="white" bgcolor="green" height="20px" width="50px" marginTop= "20px"  marginLeft = "20px"> <h4> 200</h4></Box>
                                            
                                          
                                                <Box color="white" bgcolor="pink" height="20px" width="50px" marginTop= "20px"  marginLeft = "20px"> <h4> 120</h4></Box>
                                                </Box>
                                          </Box>
                                  
                                  </Box>
        </Box>
        </Box>
        
      </Box>
    </Box>
  );
};


export default HurricaneIdalia