import { useTheme } from "@mui/material";
import { ResponsiveChoropleth } from "@nivo/geo";
import { geoFeatures } from "../data/mockGeoFeatures";
import { tokens } from "../theme";
import { mockGeographyData as data } from "../data/mockData";
import { Chart } from "react-google-charts";

const GeographyChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const data = [
    ["Country", "Catastrophe"],
    ["Morocco", 1],
    ["United States", 3],
    ["Canada" , 1],
    ["Libya", 1],
    ["India", 1]
  ];

  const options = {
    //region: "002", // Africa
    colorAxis: { colors: ["#94e2cd" , "#70d8bd" , "#4cceac"] },
    backgroundColor: "#141b2d",
    datalessRegionColor: "#2e7c67",
    defaultColor: "#70d8bd",
    legend: 'none'
    
  };

  return (

    <Chart
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
            console.log("Selected : " + region);
            
          },
        },
      ]}
      chartType="GeoChart"
      width="100%"
      data={data}
      options={options}
    />



  );
};

export default GeographyChart;