import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";
import { useNavigate } from "react-router-dom";

//import SearchIcon from "@mui/icons-material/Search";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const nav = useNavigate();

  const logout = async () => {
    await signOut(auth);
    nav("/")
  };
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"  gap = '20px'>
        <img
    alt="profile-user"
    width="140px"
    height="75px"
    src={`/galaxeye white.png`}
    style={{ cursor: "pointer", borderRadius: "50%" }}
  /> 
  <h2 class = 'logo' style = {{textAlign: 'center', fontWeight: 'bold', fontStyle: 'italic', fontSize: 25, color: '#868dfb'}}> Ensure </h2>
       
  
      </Box>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <button style = {{width: "120px", height: "50px", border: "1px solid #999",fontSize: "14px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc" , marginTop: "5%"}} onClick={logout}> Sign Out </button>

      </Box>
    </Box>
  );
};

export default Topbar;

 /*
         <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
        {" "}
        <SearchIcon />{" "}
        </IconButton>
         

      {/* ICONS */
