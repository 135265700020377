import GeographyChart from "../../components/GeographyChart";
import { Box, Button, IconButton, Typography, styled, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import Header from "../../components/Header";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function createData(
    peril: string,
    total : Number

  ) {
    return { peril, total };
  }
  
  const rows = [
    createData('Earthquake', 1),
    createData('Floods', 2),
    createData('Hailstorm', 1),
    createData('Hurricane', 2),
    createData('Wildfire', 2),
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <Box m="20px"> 
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft = '43%'>
        <Header title="DASHBOARD" subtitle="Choose a Peril" />
      </Box> 

      <Box style={{ display: "flex", gap: "20px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "25px",
          }}
        >
          <a href="/earthquake">
            <img
              src={require("../images/earthquake.jpg")}
              alt=""
              style={{ maxWidth: "275px", height: "175px" }}
            />
          </a>
          <div>
          <h2> Earthquake </h2>
          </div>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a href="/flood">
            <img
              src={require("../images/flood.jpg")}
              alt=""
              style={{ maxWidth: "275px", height: "175px" }}
            />
          </a>
          <div>
          <h2> Flood </h2>
          </div>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a href="/hailstorm">
            <img
              src={require("../images/hailstorm.jpg")}
              alt=""
              style={{ maxWidth: "275px", height: "175px" }}
            />
          </a>
          <div>
          <h2> Hailstorm </h2>
          </div>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a href="/hurricane">
            <img
              src={require("../images/hurricane.jpg")}
              alt=""
              style={{ maxWidth: "275px", height: "175px" }}
            />
          </a>
          <div>
          <h2> Hurricane </h2>
          </div>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a href="/wildfire">
            <img
              src={require("../images/wildfire.jpg")}
              alt=""
              style={{ maxWidth: "275px", height: "175px" }}
            />
          </a>
          <div>
          <h2> Wildfire </h2>
          </div>
        </Box>
      </Box>
    
      <Box>
      
      <h2 style = {{color:  '#70d8bd', fontSize: '30px' , marginLeft: "40%"}}> Catastrophes Mapped So Far.....</h2>
      <Box style = {{ display: "flex", gap: "10%"}}> 

      <Box width = '50%'
        borderRadius="4px">
        <GeographyChart />
      </Box>
      <Box style = {{marginTop: "5%"}}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} style={{backgroundColor: "rgb(46, 124, 103)" }} aria-label="customized table">
        <TableHead>
          <TableRow >
            <StyledTableCell align="right">Perils</StyledTableCell>
            <StyledTableCell align="right">Catastrophe Mapped </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              
              <StyledTableCell align="right">{row.peril}</StyledTableCell>
              <StyledTableCell align="right">{row.total}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>

      </Box>

      </Box>

      
    </Box>
  );
};

export default Dashboard;


// <Box style = {{ display: 'grid'}}> 
//       <box style={{ display: "flex", gap: "20px " }}>
//                     <Box
//                       color="white"
//                       bgcolor="#94e2cd"
//                       height="40px"
//                       width="200px"
//                     > <h2 style = {{color: "black" , marginTop: "-0.5px", marginLeft: "10%"}}> Earthquake </h2> </Box>
//                     <Box> <h2 style = {{color: "white" , marginTop: "-0.5px"}}> Morocco </h2>
//                        </Box>
                    
//       </box>
//       <box style={{ display: "flex", gap: "20px " }}>
//       <Box
//                       color="white"
//                       bgcolor="#70d8bd"
//                       height="40px"
//                       width="250px"
//                     > <h2 style = {{color: "black" , marginTop: "-0.5px", marginLeft: "10%"}}> Flood </h2> </Box>
//                     <Box> <h2 style = {{color: "white" , marginTop: "-0.5px"}}> Libya and India </h2>
//                        </Box>
      
//     </box>

//     <box style={{ display: "flex", gap: "20px " }}>
//     <Box
//     color="white"
//     bgcolor="#70d8bd"
//     height="40px"
//     width="300px"
//   > <h2 style = {{color: "black" , marginTop: "-0.5px", marginLeft: "10%"}}> Hailstorm </h2> </Box>
//   <Box> <h2 style = {{color: "white" , marginTop: "-0.5px"}}> USA </h2>
//      </Box>
      
//     </box>
//     <box style={{ display: "flex", gap: "20px " }}>
//     <Box
//     color="white"
//     bgcolor="#70d8bd"
//     height="40px"
//     width="400px"
//   > <h2 style = {{color: "black" , marginTop: "-0.5px", marginLeft: "10%"}}> Hurricane </h2> </Box>
//   <Box> <h2 style = {{color: "white" , marginTop: "-0.5px"}}> USA </h2>
//      </Box>
      
//     </box>
//     <box style={{ display: "flex", gap: "20px " }}>
//     <Box
//     color="white"
//     bgcolor="#70d8bd"
//     height="40px"
//     width="300px"
//   > <h2 style = {{color: "black" , marginTop: "-0.5px", marginLeft: "10%"}}> Wildfire </h2> </Box>
//   <Box> <h2 style = {{color: "white" , marginTop: "-0.5px"}}> USA and Canada </h2>
//      </Box>
      
//     </box>

//       </Box>
