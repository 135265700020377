import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "./Header";
import {
  MapContainer,
  TileLayer,
  Marker,
  GeoJSON,
  Polygon,
  ImageOverlay,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import "mapbox-gl/dist/mapbox-gl.css";
import Map from "react-map-gl";
import { map } from "leaflet";
import HorizontalTimeline from "react-horizontal-timeline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const Flood = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const polygonCoordinates = [
    [32.78384121521309, 22.618785075325377],
    [32.74433770083377, 22.61197676678026],
    [32.73889743854954, 22.664400742578465],
    [32.78307802837976, 22.667804896851464],
    [32.78384121521309, 22.618785075325377],
  ];
  const rasterLayerUrl = "/DernaFloodExtent-removebg-preview.png";
  const bounds = polygonCoordinates;

  // const [value, setValue] = useState(0);
  // const [previous, setPrevious] = useState(0);

  // const VALUES = [
  //   "2021-01-01",
  //   "2021-01-15",
  //   "2021-03-22",
  //   "2023-09-08",
  //   "2023-08-07",
  // ];
  // const description = [
  //   "Start Monitoring",
  //   "First Acquisition",
  //   "Peak Date",
  //   "First Analysis",
  //   "Final Analysis",
  // ];
  const nav = useNavigate();

  const handleChange = (event) => {
    nav("/deeper analysis");
  };

  const data = {
    labels: [
      "sep 8, 2023",
      "sep 12, 2023",
      "sep 13, 2023",
      "sep 20, 2023",
      "sep 24, 2023",
      "sep 25, 2023",
      "oct 02, 2023",
      "oct 06, 2023",
    ],
    datasets: [
      {
        label: "VV Backscatter",
        data: [
          -14.201, -11.523, -10.064, -12.554, -13.241, -12.15, -13.659, -12.469,
        ], // Example data points
        borderColor: "rgb(75, 192, 192)", // Line color
        borderWidth: 5, // Line width
      },
    ],
  };

  const options = {
    plugins: {  // 'legend' now within object 'plugins {}'
      legend: {
        labels: {
          color: "#c2c2c2",  // not 'fontColor:' anymore
          // fontSize: 18  // not 'fontSize:' anymore
          font: {
            size: 12 // 'size' now within object 'font {}'
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          color: '#c2c2c2',
          font: {
            size: 15 ,
            // Change the color of the y-axis labels
          },
        }
        
      },
      x: {
        ticks: {
          color: '#c2c2c2',
          font: {
            size: 13 ,
            // Change the color of the y-axis labels
          },
        }
        
      },
    },
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Flood"
          subtitle="Libya, 10.09.2023"
        />
        <Box>
          <Button
            style={{
              display: "block",
              position: "relative",
              border: " 1px solid #70d8bd",
            }}
            onClick={handleChange}
          >
            <h4 style={{ color: "whitesmoke", fontWeight: 900 }}>
              {" "}
              Deeper Analysis{" "}
            </h4>
          </Button>
        </Box>
      </Box>
      <Box style={{ display: "flex" }}>
        <MapContainer
          style={{ height: "65vh ", width: " 50% " }}
          zoom={14}
          center={[32.76615033593565, 22.634299185533223]}
        >
          <div className="custom-box">
          <Box style = {{display: 'flex', gap: '20px'}}> 
          <Box color="white" bgcolor="#0096FF" height = "10px" width = "20px"></Box>
          <h4
            style={{
              color: "#868dfb",
              marginTop: "-4px"
            }}
          >
            Affected Area
          </h4>
          </Box>
          </div>

          <TileLayer
            url={
              "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=yDbiZJmJ3zEASmbq6sx7"
            }
          ></TileLayer>
          <ImageOverlay
            url={rasterLayerUrl}
            bounds={bounds}
            zIndex={-999}
            opacity={0.7}
          ></ImageOverlay>
        </MapContainer>
        <Box style={{ display: "grid", gap: "20px", paddingLeft: "50px" }}>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height="100px" width="150px">
              <h3 style={{ marginLeft: "20px" }}> Flood Extent: </h3>
              <h2 style = {{marginLeft: "15px"}}> 22.23 sq.km </h2>
            </Box>
            <Box color="white" bgcolor="#434957" height="100px" width="150px">
              <h3 style={{ marginLeft: "25px" }}> Flood Depth: </h3>
              <h2 style = {{marginLeft: "30px"}}> Upto 3m  </h2>
            </Box>
            <Box color="white" bgcolor="#434957" height="100px" width="150px">
              <h4 style={{ marginLeft: "10px", position: "absolute" }}>
                Buildings Damaged:
              </h4>
              <h2 style = {{marginLeft: "50px" , marginTop: "8vh"}}> 851 </h2>
            </Box>
          </Box>
            <h3> Graph Of Change Detection </h3>
            <Line data={data} options={options}/>
        </Box>
      </Box>
      

    </Box>
  );
};

export default Flood;
