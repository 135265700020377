import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "./Header";
import { MapContainer, TileLayer, Marker, GeoJSON, Polygon, ImageOverlay } from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import "mapbox-gl/dist/mapbox-gl.css";
import Map from "react-map-gl";
import { map } from "leaflet";
import HorizontalTimeline from "react-horizontal-timeline";
import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, Colors
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Wildfire = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const polygonCoordinates = [
    [20.98218785539872, -156.5277291864018 ],
    [20.985067052847313, -156.53476076053914 ],
    [21.001398652885825, -156.54520780089592 ],
    [21.000264571717295, -156.5520105248491],
    [21.012512192252416, -156.55832733994848 ],
    [ 21.011831795265152, -156.56683074489004],
    [21.018408836127122,-156.57071801572044 ],
    [21.0131925861361, -156.57824960295432 ],
    [21.019542779373324, -156.58529528133448],
    [21.029521108059555, -156.58918255216486 ],
    [21.03110850785312, -156.60181618236362 ],
    [21.01772846604014, -156.60618936204781 ],
    [ 21.024305246836263, -156.6142068581356],
    [21.019769566988117, -156.62441094406535 ],
    [21.026799811932392, -156.63582980212973],
    [21.021130285431383, -156.64141775394842],
    [21.01364618033402, -156.63461502999525],
    [21.01137819554978, -156.6436043437905],
    [21.004800844686173, -156.65016411331678],
    [21.005708082733804, -156.66692796877294],
    [ 20.999130481931815, -156.66425547007705],
    [20.99504768736081, -156.66911455861504],
    [20.988696451664453, -156.66717092319982],
    [20.980303333073692, -156.67591728256832],
    [20.957389981633, -156.68490663315112],
    [20.948995105265567, -156.6917093571043],
    [20.932430860101505, -156.6929241292388],
    [20.916999504221266, -156.69535367350778],
    [20.905833626260772, -156.68296304145085],
    [20.88177390714702, -156.6880650844157],
    [20.879957922794702, -156.68199122374324],
    [20.870650658412814, -156.67761804405905],
    [20.85907252502963, -156.66595623156778],
    [20.842451731923575, -156.64807602730838],
    [20.821427530913937, -156.6323297116757],
    [20.810914329920436, -156.5974628699178],
    [20.796194616728158, -156.57834234379243],
    [20.779370328618896, -156.53335287055643],
    [20.803554652920383, -156.50073550246017],
    [20.800400395671403, -156.46811813436403],
    [20.780421901565063, -156.45912023971687],
    [20.740456983791987, -156.45124708190045],
    [20.67943754714942, -156.4377502399297],
    [20.635235736475863, -156.44899760823867],
    [20.611024538416117, -156.42875234528253],
    [20.588915300878696, -156.41075655598806],
    [ 20.57628001153556, -156.37139076690642],
    [20.588915300878696, -156.330900240994],
    [20.584703654032396, -156.29940760972877],
    [20.59523255309047, -156.284786030927],
    [20.62681488691389, -156.1891834003004],
    [20.623656948209657, -156.1318218219243],
    [20.64997110171666, -156.09020655918098],
    [20.65628582113257, -156.05196550693023],
    [20.681542074492825, -156.01372445467962],
    [20.736249535825493, -155.9799823497526],
    [20.786731185429474, -155.99797813904695],
    [20.831939998349213, -156.0800839277029],
    [20.825632605932, -156.11495076946085],
    [20.852962731334387, -156.15994024269696],
    [20.88764381671045, -156.20043076860935],
    [20.93072127973953, -156.22405024205833],
    [20.94227654176852, -156.2814118204343],
    [20.940175651356995, -156.3342744514867],
    [20.907608081860033, -156.41638024014253],
    [20.889745443369776, -156.479365502673],
    [20.929670757164715, -156.50748392344568],
    [20.98218785539872, -156.5277291864018],
  ];
  const rasterLayerUrl = "/WildfireHawaii-removebg-preview.png";
  const bounds = polygonCoordinates;

 

  const tempData = {
    labels: ["Aug 7, 2023", "Aug 14, 2023", "Aug 23, 2023", "Aug 30, 2023"],
    datasets: [
      {
        label: "Temp. (in K) ",
        data: [397.498, 584.419, 485.14, 598.423], // Example data points
        borderColor: "yellow", // Line color
        borderWidth: 5
         // Line width
      },
    ],
  };
  const MoistureData = {
    labels: [
      "Aug 7, 2023",
      "Aug 14, 2023",
      "Aug 14, 2023",
      "Aug 23, 2023",
      "Aug 30, 2023",
      "Aug 30, 2023",
    ],
    datasets: [
      {
        label: "Moisture Index",
        data: [0.078, 0.085, 0.074, 0.082, 0.065, 0.052], // Example data points
        borderColor: "green", // Line color
        borderWidth: 5, // Line width
      },
    ],
  };
  const options = {
    plugins: {  // 'legend' now within object 'plugins {}'
      legend: {
        labels: {
          color: "#c2c2c2",  // not 'fontColor:' anymore
          // fontSize: 18  // not 'fontSize:' anymore
          font: {
            size: 12 // 'size' now within object 'font {}'
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          color: '#c2c2c2',
          font: {
            size: 15 ,
            // Change the color of the y-axis labels
          },
        }
        
      },
      x: {
        ticks: {
          color: '#c2c2c2',
          font: {
            size: 10 ,
            // Change the color of the y-axis labels
          },
        }
        
      },
    },
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Wildfire"
          subtitle="Hawaii, USA, 08.08.2023"
        />
      </Box>
      <Box style={{ display: "flex" }}>
        <MapContainer
          style={{ height: "65vh ", width: " 50% " }}
          zoom={10}
          center={[20.883914041613565, -156.3750104371973]}
        >
          <div className="custom-box">
          <Box style = {{display: 'flex', gap: '20px'}}> 
          <Box color="white" bgcolor="red" height = "10px" width = "20px"></Box>
          <h4
            style={{
              color: "#868dfb",
              marginTop: "-4px"
            }}
          >
            Maximum Affected Area
          </h4>
          <Box color="white" bgcolor="yellow" height = "10px" width = "20px"></Box>
          <h4
            style={{
              color: "#868dfb",
              marginTop: "-4px"
            }}
          >
            Medium Affected Area
          </h4>
          <Box color="white" bgcolor="blue" height = "10px" width = "20px"></Box>
          <h4
            style={{
              color: "#868dfb",
              marginTop: "-4px"
            }}
          >
           Least Affected Area
          </h4>
          </Box>
          </div>

          <TileLayer
            url={
              "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=yDbiZJmJ3zEASmbq6sx7"
            }
          ></TileLayer>
          <ImageOverlay
            url={rasterLayerUrl}
            bounds={bounds}
            zIndex={-999}
            opacity={0.7}
          ></ImageOverlay>
        </MapContainer>
        <Box style={{ display: "grid", gap: "20px", paddingLeft: "30px" }}>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height="160px" width="300px">
              <h2 style={{ marginLeft: "90px" }}> Total Area: </h2>
              <h1 style={{ marginLeft: "15%" }}> 1845.54 sq.Km </h1>
            </Box>
            <Box color="white" bgcolor="#434957" height="160px" width="300px">
              <h2 style={{ marginLeft: "25px" }}> No.of Buildings Damaged: </h2>
              <h4 style={{ marginLeft: "10%" }}> [Buildings in Lahaina] </h4>
              <h1 style={{ marginLeft: "10%" }}> 747 / 1722 </h1>
            </Box>
          </Box>{" "}
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height="250px" width="300px">
              <h2 style={{ marginLeft: "50px" }}> Temperature Anomaly </h2>
              <Line  data={tempData} options={options} />
            </Box>
            <Box color="white" bgcolor="#434957" height="250px" width="300px">
              <h2 style={{ marginLeft: "80px" }}> Moisture Index </h2>
              <Line data={MoistureData} options={options} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Wildfire;
