import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "./Header";
import {
  MapContainer,
  TileLayer,
  Marker,
  GeoJSON,
  Polygon,
  ImageOverlay,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import "mapbox-gl/dist/mapbox-gl.css";
import Map from "react-map-gl";
import { map } from "leaflet";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router-dom";

const HurricaneIanMicro = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const polygonCoordinates = [
    [29.138161366583702, -83.03735613006],
    [29.1380570678283, -83.03730944332575],
    [29.138050794215616, -83.03729597599826],
    [29.138071967657197, -83.0372537783729],
    [29.138046089006167, -83.03723312847123],
    [29.138092356892614, -83.03713975500234],
    [29.13804138379581, -83.03710384212994],
    [29.138069615052657, -83.03704638153341],
    [29.138129214347217, -83.03707511183168],
    [29.138166855988402, -83.03698802311573],
    [29.138284486030287, -83.0370580532173],
    [29.138161366583702, -83.03735613006],
  ];
  // const rasterLayerUrl = "/MoroccoHotspots.png";
  // const bounds = polygonCoordinates;

  const data = {
    labels: ["30% property is damaged"],
    datasets: [
      {
        data: [30, 70],
        backgroundColor: ["yellow", "white"],
        borderColor: ["yellow", "white"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {  // 'legend' now within object 'plugins {}'
      legend: {
        labels: {
          color: "#c2c2c2",  // not 'fontColor:' anymore
          // fontSize: 18  // not 'fontSize:' anymore
          font: {
            size: 15 // 'size' now within object 'font {}'
          }
        }
      }
    },
  }

  const nav = useNavigate();

  const handleChange = (event) => {
    nav("/hurricane idalia");
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Property Damage" subtitle=" During Hurricane Idalia " />
        <Button
        style = {{width: "120px", height: "50px", border: "1px solid #999",fontSize: "12px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc", marginTop: "-10px"}}
            onClick = {handleChange}>
              <h4>
                Go Back
              </h4>
            </Button>
      </Box>
      <Box style={{ display: "flex" }}>
        <MapContainer
          style={{ height: "65vh ", width: " 50% " }}
          zoom={20}
          center={[29.138129214347217, -83.03707511183168]}
        >
          <div className="custom-box">
            <h4
              style={{
                textAlign: "center",
                color: "#70d8bd",
              }}
            >
              {" "}
              Affected Area:{" "}
            </h4>
          </div>

          <TileLayer
            url={
              "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=yDbiZJmJ3zEASmbq6sx7"
            }
          ></TileLayer>
          <Polygon
            positions={polygonCoordinates}
            color="yellow" // Set the boundary color to red
          />
        </MapContainer>
        <Box style={{ display: "grid", gap: "20px", paddingLeft: "50px" }}>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height="100px" width="300px">
              <h2 style={{ marginLeft: "90px" }}> Coordinates: </h2>
              <h1 style = {{marginLeft: "20px", marginTop: "-5px"}}> -83.03718,
              29.13817 </h1>
            </Box>
            <Box color="white" bgcolor="#434957" height="100px" width="300px">
              <h2 style={{ marginLeft: "70px" }}> Roof Material: </h2>
              <h1 style = {{marginLeft: "75px", marginTop: "-5px"}}> Concrete </h1>
            </Box>
          </Box>{" "}
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height="100px" width="300px">
              <h2 style={{ marginLeft: "50px" }}> Shape of the Roof: </h2>
              <h1 style = {{marginLeft: "75px", marginTop: "-5px"}}> Mixed Roof </h1>
            </Box>
            <Box color="white" bgcolor="#434957" height="100px" width="300px">
              <h2 style={{ marginLeft: "100px" }}> Age: </h2>
              <h1 style = {{marginLeft: "75px", marginTop: "-5px"}}> 5- 10 years</h1>
            </Box>
          </Box>
          <Box color="white" height="200px" width="300px" marginLeft = "35%">
            <Doughnut data={data} options = {options}/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HurricaneIanMicro;
