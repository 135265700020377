import { useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, check } from "../firebase-config";
import { Box } from "@mui/material";


function Login({isUserRegistered,setIsUserRegistered}) {
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage,setErrorMessage]=useState("")
  const [user, setUser] = useState({});
  useEffect(() => {

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });},[])
  const n = useNavigate(); 
  const nav = useNavigate();

  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      console.log(user);
      setErrorMessage("")
      setIsUserRegistered(true)
      window.location.reload(false);
    } catch (error) {
      setErrorMessage(error.message)
      console.log(error.message);
    }
  };
  
  

  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      console.log(user);
      setIsUserRegistered(false)

    
        nav("/dashboard");
    

    } catch (error) {
      console.log(error.message);
    }
  };

  

  return (
    <div>
    <Box display="flex" justifyContent="center" alignItems="center" marginTop= "10%">
    <h1 style = {{marginRight: "20px" , fontSize: "50px"}}> Welcome to </h1>
    <img
      alt=""
      width="200px"
      height="100px"
      src={`/galaxeye white.png`}
      style={{ cursor: "pointer", borderRadius: "50%" }}
    />
    <h1 style = {{marginLeft: "20px", color: "rgb(134, 141, 251)" , fontSize: "50px"}}> Ensure! </h1>
  </Box>
      <div style = {{marginLeft: "30%"}}>
        <h3> Register User </h3>
        <input style = {{marginRight: "20px" , width: "200px", height: "20px", border: "1px solid #999",fontSize: "14px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc"}}
          placeholder="Email..."
          onChange={(event) => {
            setRegisterEmail(event.target.value);
          }}
        />
        <input style = {{width: "200px", height: "20px", border: "1px solid #999",fontSize: "14px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc"}}
          placeholder="Password..."
          onChange={(event) => {
            setRegisterPassword(event.target.value);
          }}
        />

        <button style = {{width: "120px", height: "50px", border: "1px solid #999",fontSize: "14px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc", marginLeft: "5%"}} onClick={register}> Create User</button>
      </div>

      <div style = {{marginLeft: "30%"}}>
        <h3> Login </h3>
        <input style = {{marginRight: "20px", width: "200px", height: "20px", border: "1px solid #999",fontSize: "14px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc"}}
          placeholder="Email..."
          onChange={(event) => {
            setLoginEmail(event.target.value);
          }}
        />
        <input style = {{width: "200px", height: "20px", border: "1px solid #999",fontSize: "14px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc"}}
          placeholder="Password..."
          onChange={(event) => {
            setLoginPassword(event.target.value);
          }}
        />

        <button style = {{width: "120px", height: "50px", border: "1px solid #999",fontSize: "14px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc", marginLeft: "5%"}} onClick={login}> Login</button>
      </div>

    
          <h3 style = {{marginLeft: "40%", marginTop: "50px"}}> {errorMessage} </h3>
          {isUserRegistered&&<h3 style = {{marginLeft: "40%", marginTop: "50px"}}>User Registered </h3>}
          
    </div>
  );
}

export default Login

//   <h4> User Logged In: </h4>
    //   {user?.email}