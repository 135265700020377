import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "./Header";
import {MapContainer, TileLayer, Marker, GeoJSON, Polygon, ImageOverlay} from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import "mapbox-gl/dist/mapbox-gl.css";
import Map from "react-map-gl";
import { map } from "leaflet";
import { useState } from "react";

//import { MapContainer, GoogleApiWrapper } from "../components/maps";

const Quake = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const polygonCoordinates = [
    [31.6854576930659, -7.992802395829386],
    [31.676288135304432, -8.002394941162152],
    [31.659573700867668, -8.015850956267627],
    [31.65028660447291, -8.020033231232645],
    [31.637747552309236, -8.017305660604023],
    [31.629232417175587, -8.01475992801565],
    [31.616845374600317, -8.007122730253144],
    [31.61514202729444, -8.003485969414129],
    [31.61219980852627, -8.004031483540103],
    [31.605076156919708, -7.998576342280245],
    [31.594389657598043, -7.991484658643742],
    [31.614677472619036, -7.948389042697329],
    [31.616845374600317, -7.955298888292276],
    [31.623039102014133, -7.971300635984846],
    [31.628807751722064, -7.972982049254938],
    [31.63376212961748, -7.978982704640799],
    [31.637787367347883, -7.980801085060307],
    [31.641967237512205, -7.986619902403305],
    [31.64490851499484, -7.9877109306552825],
    [31.659458729290336, -7.984801521983769],
    [31.663637625034795, -7.9895293110748185],
    [31.669982996174227, -7.990984015409879],
    [31.6753994334496, -7.992075043661856],
    [31.68174400102687, -7.991711367577409],
    [31.6854576930659, -7.992802395829386],
  ];
  const rasterLayerUrl = "/MoroccoHotspots-removebg-preview.png";
  const bounds = polygonCoordinates;

  

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Earthquake"
          subtitle=" Morocco, 08.09.2023, 11pm"
        />
      </Box>
      <Box style={{ display: "flex" }}>
        <MapContainer
          style={{ height: "65vh ", width: " 50% " }}
          zoom={12}
          center={[31.659458729290336, -7.984801521983769]}
        >
          <div className="custom-box">
            
            <Box style = {{display: 'flex', gap: '20px'}}> 
            <Box color="white" bgcolor="red" height = "10px" width = "20px"></Box>
            <h4
              style={{
                color: "#868dfb",
                marginTop: "-4px"
              }}
            >
              Affected Area
            </h4>
            </Box>

          </div>

          <TileLayer
            url={
              "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=yDbiZJmJ3zEASmbq6sx7"
            }
          ></TileLayer>

          <ImageOverlay
            url={rasterLayerUrl}
            bounds={bounds}
            zIndex={-999}
            opacity={1}
          ></ImageOverlay>
        </MapContainer>
        <Box style={{ display: "grid", gap: "20px", paddingLeft: "30px" }}>
          <Box style={{ display: "flex" , gap: "20px"}}>
            <Box color="white" bgcolor="#434957" height = "200px" width = "300px">
              <h2 style = {{marginLeft :"90px"}}> Total Area: </h2>
              <h1 style = {{marginLeft: '45px', fontSize: '40px'}}> 28.25 sq.km </h1>
            </Box>
            <Box color="white" bgcolor="#434957" height = "200px" width = "300px">
              <h2 style = {{marginLeft :"25px"}}> No.of Buildings Damaged: </h2>
              <h1 style = {{marginLeft: '85px', fontSize: '40px'}}> 700+ </h1>
            </Box>
          </Box>{" "}
          <Box style={{ display: "flex" , gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height = "200x" width = "300px">
              <h2 style = {{marginLeft :"100px"}}> Epicenter: </h2>
              <h1 style = {{marginLeft: '5px', fontSize: '40px'}}> 44 miles, south of Marrakesh</h1>
            </Box>
            <Box color="white" bgcolor="#434957" height = "250px" width = "300px">
              <h2 style = {{marginLeft :"50px"}}> Magnitude and Depth: </h2>
              <h1 style = {{marginLeft: '90px', fontSize: '40px'}}> 6.8 and 11.5 miles </h1>
            </Box>
          </Box>
        </Box>
      </Box>

    </Box>
  );
};

export default Quake;

// <Polygon positions={polygonCoordinates} />

//<h2 style={{ textAlign: "center", color: "#70d8bd" }}> Timeline Of The Event </h2>
