import { initializeApp } from "firebase/app";
import {getAuth, } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCpiD1bxNQLWi_suytf4uDe0kQYS0aDkdE",
    authDomain: "natcat-dashboard.firebaseapp.com",
    projectId: "natcat-dashboard",
    storageBucket: "natcat-dashboard.appspot.com",
    messagingSenderId: "846796167122",
    appId: "1:846796167122:web:452e7d99fca09d87bbda0b",
    measurementId: "G-N24NFG0G6E"
  };

const app = initializeApp(firebaseConfig); 
export var check=false
export const auth = getAuth(app)