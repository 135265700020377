import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "./Header";
import {
  MapContainer,
  TileLayer,
  Marker,
  GeoJSON,
  Polygon,
  ImageOverlay,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import "mapbox-gl/dist/mapbox-gl.css";
import Map from "react-map-gl";
import { map } from "leaflet";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import turf from '@turf/turf';
import { useNavigate } from "react-router-dom";

const HurricaneIanMicro = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const polygonCoordinates = [
    [26.562506363682672, -82.01255230383204],
    [26.562475200904032, -82.01255455159158],
    [26.56234049717952, -82.01255567547105],
    [26.562343512935925, -82.01251072028671],
    [26.56234250768378,-82.01246576510235],
    [26.562356581214743, -82.01246688898186],
    [26.56235859171875, -82.01244103975039],
    [26.562343512935925, -82.01244103975039],
    [26.56234049717952, -82.01240282784407],
    [26.562338486675173, -82.01231628911383],
    [26.562339491927318, -82.01227245780896],
    [26.56235356545868, -82.01227807720694],
    [26.56235457071071, -82.01224436081854],
    [26.56234250768378, -82.01223874142057],
    [26.562337481423015, -82.0122050250322],
    [26.56234250768378, -82.01208139827457],
    [26.562351554953565, -82.01208701767305],
    [26.562352560205667, -82.01204431024772],
    [26.562341502431664, -82.01204880576621],
    [26.56233647617084, -82.0120105938593],
    [26.562334465665458, -82.01195777151746],
    [26.562502342679807, -82.0119611431565],
    [26.562506363682672, -82.01255230383204],
  ];
  // const rasterLayerUrl = "/MoroccoHotspots.png";
  // const bounds = polygonCoordinates;
  // const turf = require('@turf/turf');
  // const area = turf.area(polygonCoordinates);

  const data = {
    labels: ["90% property is damaged"],
    datasets: [
      {
        data: [90, 10],
        backgroundColor: ["red", "white"],
        borderColor: ["red", "white"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {  // 'legend' now within object 'plugins {}'
      legend: {
        labels: {
          color: "#c2c2c2",  // not 'fontColor:' anymore
          // fontSize: 18  // not 'fontSize:' anymore
          font: {
            size: 15 // 'size' now within object 'font {}'
          }
        }
      }
    },
  }

  const nav = useNavigate();

  const handleChange = (event) => {
    nav("/hurricane ");
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Individual Roof Damage Assessment" subtitle=" During Hurricane Ian " />
        <Button
        style = {{width: "120px", height: "50px", border: "1px solid #999",fontSize: "12px", color: "#1c87c9",bgcolor: "#eee", borderRadius: "5px",boxShadow: "4px 4px #ccc", marginTop: "-10px"}}
            onClick = {handleChange}>
              <h4>
                Go Back
              </h4>
            </Button>
      </Box>

     
  
      <Box style={{ display: "flex" }}>
        <MapContainer
          style={{ height: "65vh ", width: " 50% " }}
          zoom={20}
          center={[26.56233647617084, -82.0120105938593]}
        >

          <TileLayer
            url={
              "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=yDbiZJmJ3zEASmbq6sx7"
            }
          ></TileLayer>
          <Polygon
            positions={polygonCoordinates}
            color="red" // Set the boundary color to red
          />
        </MapContainer>
        <Box style={{ display: "grid", gap: "20px", paddingLeft: "50px" }}>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height="100px" width="300px">
              <h2 style={{ marginLeft: "90px" }}> Coordinates: </h2>
              <h1 style = {{marginTop: "-5px", marginLeft: "15px"}}> -82.01255, 26.56247</h1>
            </Box>
            <Box color="white" bgcolor="#434957" height="100px" width="300px">
              <h2 style={{ marginLeft: "50px" }}> Roof Material: </h2>
              <h1 style = {{marginTop: "-5px", marginLeft: "65px"}}> Asphalt </h1>
            </Box>
          </Box>{" "}
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height="100px" width="300px">
              <h2 style={{ marginLeft: "50px" }}> Shape of the Roof: </h2>
              <h1 style = {{marginTop: "-5px", marginLeft: "65px"}}> Gable Roof </h1>
            </Box>
            <Box color="white" bgcolor="#434957" height="100px" width="300px">
              <h2 style={{ marginLeft: "100px" }}> Age: </h2>
              <h1 style = {{marginTop: "-5px", marginLeft: "65px"}}> 5- 10 years</h1>
            </Box>
          </Box>
          <Box color="white" height="200px" width="300px" marginLeft= "30%">
            <Doughnut data={data} options = {options} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HurricaneIanMicro;
