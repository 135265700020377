import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LandslideSharpIcon from '@mui/icons-material/LandslideSharp';
import FloodSharpIcon from '@mui/icons-material/FloodSharp';
import ThunderstormSharpIcon from '@mui/icons-material/ThunderstormSharp';
import CycloneSharpIcon from '@mui/icons-material/CycloneSharp';
import WhatshotSharpIcon from '@mui/icons-material/WhatshotSharp';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import EmailIcon from '@mui/icons-material/Email';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          height: '100vh'
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
              <Typography variant="h6" color={colors.grey[100]}>
                Welcome To The Ensure!
              </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Hi There, 
                </Typography>
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ m: "10px 0 0 0" }}
                >
                 This is your Dashboard 
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Disasters
            </Typography>
            <Item
              title="Earthquake"
              to="/earthquake"
              icon={<LandslideSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Flood"
              to="/flood"
              icon={<FloodSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Hailstorm"
              to="/hailstorm"
              icon={<ThunderstormSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Hurricane"
              to="/hurricane"
              icon={<CycloneSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Wildfire"
              to="/wildfire"
              icon={<WhatshotSharpIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Box style = {{border: "1px white solid", width: "400px", marginLeft: "-25px" , marginTop: "5px"}}> </Box>
            <Item
              icon={<EmailIcon />}
              selected={selected}
              setSelected={setSelected}
              title = "Send us a Mail"
            ></Item>
            <h4 style = {{marginTop: "-5px" , marginLeft: "20px"}}> suyash@galaxeye.space </h4>
            <h4 style = {{marginTop: "-20px" , marginLeft: "20px"}}> pranit@galaxeye.space </h4>
            <h4 style = {{marginTop: "-20px", marginLeft: "5px"}}> ayushee.nandani@galaxeye.space </h4>

            
            
            
            
        
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;


 /* <Typography variant="h3" color={colors.grey[100]}>
                //   USER INFO
                // </Typography> */



              //   <Box display="flex" justifyContent="center" alignItems="center">
              //   <img
              //     alt="profile-user"
              //     width="100px"
              //     height="100px"
              //     src={`/user.png`}
              //     style={{ cursor: "pointer", borderRadius: "50%" }}
              //   />
              // </Box>
