import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "./Header";
import {
  MapContainer,
  TileLayer,
  Marker,
  GeoJSON,
  Polygon,
  ImageOverlay,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import "mapbox-gl/dist/mapbox-gl.css";
import Map from "react-map-gl";
import { map } from "leaflet";
import HorizontalTimeline from "react-horizontal-timeline";
import { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale,PointElement,LineElement, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FloodTwo = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const rasterLayerUrl = "/dernaBuildingsPOC-removebg-preview.png";
  const bounds = [[32.766499145901605, 22.635389203130053], [32.77377549970297, 22.64913342697708]];
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: [ "233" , "618"],
    datasets: [
      {
        label: "Buildings Damaged/Destroyed: ",
        data: [ 233, 618],
        backgroundColor: ["yellow", "red"],
        borderColor: ["yellow", "red"],
        borderWidth: 1,
      },
    ],
  };
  const option1 = {
    plugins: {  // 'legend' now within object 'plugins {}'
      legend: {
        labels: {
          color: "#c2c2c2",  // not 'fontColor:' anymore
          // fontSize: 18  // not 'fontSize:' anymore
          font: {
            size: 12 // 'size' now within object 'font {}'
          }
        }
      }
    },
  };

  const destroyedBuildings = {
    labels: [ "preFlood" , "postFlood"
    ],
    datasets: [
      {
        label: "VV Backscatter",
        data: [
          -10.86, -3.11
        ], // Example data points
        borderColor: "red", // Line color
        borderWidth: 5, // Line width
      },
    ],
  };

  const damagedBuildings = {
    labels: [ "preFlood" , "postFlood"
    ],
    datasets: [
      {
        label: "VV Backscatter",
        data: [
          -11.5, -9.917
        ], // Example data points
        borderColor: "yellow", // Line color
        borderWidth: 5, // Line width
      },
    ],
  };

  const options = {
    plugins: {  // 'legend' now within object 'plugins {}'
      legend: {
        labels: {
          color: "#c2c2c2",  // not 'fontColor:' anymore
          // fontSize: 18  // not 'fontSize:' anymore
          font: {
            size: 12 // 'size' now within object 'font {}'
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          color: '#c2c2c2',
          font: {
            size: 12 ,
            // Change the color of the y-axis labels
          },
        }
        
      },
      x: {
        ticks: {
          color: '#c2c2c2',
          font: {
            size: 13 ,
            // Change the color of the y-axis labels
          },
        }
        
      },
    },
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Flood"
          subtitle="Libya, 10.09.2023"
        />
      </Box>

      <Box style={{ display: "flex" }}>
        <MapContainer
          style={{ height: "65vh ", width: " 50% " }}
          zoom={15}
          center={[32.77395980889513, 22.64323074472503]}
        >
          <TileLayer
            url={
              "https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=yDbiZJmJ3zEASmbq6sx7"
            }
          ></TileLayer>
          <ImageOverlay
            url={rasterLayerUrl}
            bounds={bounds}
            zIndex={-999}
            opacity={0.7}
          ></ImageOverlay>
        </MapContainer>
        <Box style={{ display: "grid", gap: "20px", paddingLeft: "20px" }}>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box color="white" bgcolor="#434957" height="200px" width="300px">
              <Box style={{ border: "1px solid white" , height: "200px"}}>
                <h4 style={{ marginLeft: "5 px" }}>
                  {" "}
                  Total Number Of Buildings Affected By Flood Water:{" "}
                </h4>
                <box style={{ display: "flex", gap: "20px " }}>
                  <Box
                    color="white"
                    bgcolor="red"
                    height="40px"
                    width="175px"
                  > </Box>
                  <Box>
                    <h4 style = {{marginTop: "-7px"}}> Buildings Destroyed </h4> </Box>
                  
                </box>
                <box style={{ display: "flex", gap: "20px " }}>
                  <Box
                    color="white"
                    bgcolor="yellow"
                    height="40px"
                    width="100px"
                  > </Box>
                  <Box>
                    <h4> Buildings Damaged </h4> </Box>
                  
                </box>
              </Box>
            </Box>
            <Box color="white" bgcolor="#434957" height="200px" width="300px">
              <Doughnut style = {{marginLeft: "15%"}} data={data} options = {option1}/>
            </Box>
          </Box>
        <Box style = {{display: "flex" , gap: "20px"}}> 
            <Box color="white" bgcolor="#434957" height="225px" width="300px"> 
              <h3 style = {{marginLeft: "20px"}}> Destroyed Building </h3>
              <Line data={destroyedBuildings} options={options} />

            </Box>
            <Box color="white" bgcolor="#434957" height="225px" width="300px"> 
              <h3 style = {{marginLeft: "20px"}}> Damaged Building </h3>
              <Line data={damagedBuildings} options={options} />

            </Box>
        </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FloodTwo;
