import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar.jsx"
import Sidebar from "./scenes/global/Sidebar.jsx"
import Dashboard from "./scenes/dashboard/index.jsx";
import Geography from "./scenes/geography";
import { useState } from "react";
import Quake from './components/Quake.jsx'
import Flood from "./components/Flood.jsx"
import Hailstorm from "./components/Hailstorm.jsx"
import Hurricane from "./components/Hurricane.jsx"
import Wildfire from "./components/Wildfire.jsx"
import FloodTwo from "./components/FloodTwo"
import  HurricaneIanMicro from './components/HurricaneIanMicro.jsx'
import HurricaneIdalia from "./components/HurricaneIdalia.jsx"
import HurricaneIdaliaMicro from "./components/HurricaneIdaliaMicro.jsx"
import Login from "./components/Login"

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const [isUserRegistered,setIsUserRegistered]=useState(false)

  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme = {theme}> 
    <CssBaseline/> 
      <div className="app">
      {location.pathname === "/" ||location.pathname === "/dashboard" ? <></> : <Sidebar isSidebar={ isSidebar} />}
      
      <main className = "content"> 
      {location.pathname !== "/" &&<Topbar setIsSidebar={setIsSidebar} />}
      <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Login 
        isUserRegistered={isUserRegistered}
        setIsUserRegistered={setIsUserRegistered}/>} />
      <Route path="/geography" element={<Geography />} />
      <Route path="/earthquake" element={<Quake />} />
      <Route path="/flood" element={<Flood />} />
      <Route path="/deeper analysis" element={<FloodTwo />} />
      <Route path="/hailstorm" element={<Hailstorm />} />
      <Route path="/hurricane" element={<Hurricane />} />
      <Route path="/deeper analysis for Hurricane Ian" element={< HurricaneIanMicro />} />
      <Route path="/hurricane idalia" element={< HurricaneIdalia />} />
      <Route path="/deeper analysis for Hurricane Idalia" element={< HurricaneIdaliaMicro />} />
      <Route path="/wildfire" element={<Wildfire />} />
      
      </Routes>
      </main>
      </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

//<Sidebar isSidebar={isSidebar} />
