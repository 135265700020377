import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "./Header";

//import "mapbox-gl/dist/mapbox-gl.css";

const Hailstorm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="HailStorm"
          subtitle= " In Production"
        />
        </Box>
        <Box> 
            <h2> With our fusion data, it is now possible to analyse hail damage remotely. The image below was collected from one of our studies and vividly depicts the designed crack on metal tiles. </h2>
            <h3> We are currently fine-tuning and expanding on this functionality. </h3>
        </Box>
        <Box> 
        <img
        src={'/crackSee.png'}
        alt=""
        style={{ width: "65vh", height: "40vh" , marginLeft: "30%"}}
      />
        </Box>
    </Box>
  );
};


export default Hailstorm